import React from "react";

const Footer = () => {
  return (
        <footer className="text-center mt-6">
            <p className="text-sm text-gray-500">
                This website is currently a work in progress, so some links might not fully work, etc.
            </p>
        </footer>
  );
};

export default Footer;
