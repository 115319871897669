import React from 'react';
import { Link } from 'react-router-dom';

function NoMatch() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-base-200 text-center px-4">
      <h2 className="text-2xl md:text-4xl font-semibold mt-4">Oops! Page Not Found</h2>
      <p className="text-lg mt-2 mb-6 text-gray-600">The page you're looking for doesn't exist or has been moved.</p>
      <Link to="/" className="btn btn-primary btn-wide">
        Go Back to Home
      </Link>
    </div>
  );
}

export default NoMatch;