import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.svg";

const Header = () => {
  return (
    <header className="navbar bg-base-200 shadow-lg">
      <div className="flex-1 flex items-center">
        <a href="/" className="flex items-center space-x-2">
          <img
            src={logo}
            alt="Site Logo"
            className="w-10 h-10 object-contain"
          />
          <span className="text-xl font-bold primary-content">CalcuBest</span>
        </a>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal p-0">

          {/* Dropdown Component */}
          <li className="dropdown dropdown-hover">
            <Link tabIndex={0} role="button" to="/finance">
              Finance ▾
            </Link>
            <ul
              tabIndex="0"
              className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
            >
              <li>
                <Link to="/finance/putapy">Cash Secured Put</Link>
              </li>
              <li>
              <Link to="/finance/callapy">Covered Call</Link>
              </li>
              <li>
              <Link to="/finance/apy">APY Interest</Link>
              </li>
              <li>
              <Link to="/finance/pricetowage">Price to Wage</Link>
              </li>
              <li>
              <Link to="/finance/hourlywage">Anuual Wage</Link>
              </li>
              <li>
              <Link to="/finance/nycholiday">NYC Holiday Tip Amount</Link>
              </li>
              <li>
              <Link to="/finance/nycrent">NYC Rent Affordability</Link>
              </li>
            </ul>
          </li>

          {/* Dropdown Component */}
          <li className="dropdown dropdown-hover">
            <Link tabIndex={0} role="button" to="/tech">
              Tech ▾
            </Link>
            <ul
              tabIndex="0"
              className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
            >
              <li>
                <Link to="/tech/oklch">Okclh Picker</Link>
              </li>
              <li>
              <Link to="/tech/subnet">Subnetting Calculator</Link>
              </li>
            </ul>
          </li>

          <li className="dropdown dropdown-hover">
            <Link tabIndex={0} role="button" to="/tech">
              Health ▾
            </Link>
            <ul
              tabIndex="0"
              className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
            >
              <li>
                <Link to="/health/tdee">TDEE</Link>
              </li>
              <li>
              <Link to="/health/height">Height Calculator</Link>
              </li>
            </ul>
          </li>

         {/* <li>
            <a href="#contact" className="hover:bg-primary-focus">
              CHANGE TO LINKS IF ON SMALL SCREEN
            </a>
          </li>
          */}
        </ul>
      </div>
    </header>
  );
};

export default Header;
