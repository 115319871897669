import React from 'react';
import CalculatorIndex from './CalculatorIndex';

const HomePage = () => {
  return (
    <div>
        <CalculatorIndex />
    </div>
  );
};

export default HomePage;
