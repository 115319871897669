import React, { useState } from "react";

const NYCRentCalculator = () => {
  const [values, setValues] = useState({
    monthlyRent: "",
    annualIncome: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!/^\d*\.?\d*$/.test(value)) return; // Allow only numeric input

    if (name === "monthlyRent") {
      const monthlyRent = parseFloat(value) || 0;
      const annualIncome = monthlyRent * 40;
      setValues({
        monthlyRent: value,
        annualIncome: annualIncome ? annualIncome.toFixed(2) : "",
      });
    } else if (name === "annualIncome") {
      const annualIncome = parseFloat(value) || 0;
      const monthlyRent = annualIncome / 40;
      setValues({
        monthlyRent: monthlyRent ? monthlyRent.toFixed(2) : "",
        annualIncome: value,
      });
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-base-200 rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">40x Rule Rent Calculator</h2>
      <form className="space-y-4">
        <div>
          <label className="label">
            <span className="label-text">Monthly Rent</span>
          </label>
          <input
            type="text"
            name="monthlyRent"
            value={values.monthlyRent}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Enter monthly rent"
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Required Annual Income</span>
          </label>
          <input
            type="text"
            name="annualIncome"
            value={values.annualIncome}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Enter annual income"
          />
        </div>
      </form>
    </div>
  );
};

export default NYCRentCalculator;
