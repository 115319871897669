import React from 'react';
import LinkList from '../Helpers/LinkList';

const OtherIndex = () => {
    const links = [
        { title: 'Scientific Calculator', url: '/other/scientificcalculator/', description: 'Powerful scientific calculator' }
      ];
    
      return (
          <div className="flex flex-col items-center p-4">
            <h2 className="text-2xl font-bold mb-4">Other</h2>
            <LinkList links={links} />
          </div>
      );
};

export default OtherIndex;