import React, { useState } from 'react';
import TitleText from '../Helpers/TitleText';
import Description from '../Helpers/Description';

function NYCTipCalculator() {
  const [feeType, setFeeType] = useState('Rent');
  const [feeAmount, setFeeAmount] = useState(3150);
  const [numDoormen, setNumDoormen] = useState(5);
  const [numPorters, setNumPorters] = useState(2);
  const numSupers = 1;
  const [numHandymen, setNumHandymen] = useState(0);
  const SUPER ="calcubest";
  const PORTER = "error"
  const HANDYMEN = "warning"
  const DOORMEN = "foreach"

  const mainText = `Holiday tipping in New York City is a deeply ingrained social tradition. If you live in a building with workers like doormen or a superintendents, you should tip in December.
<br />
You should tip every doormen, superintendent, porter, and handymen in your building, even if you don't interact with them much. They all work together to maintain and run the building. These workers are often the first line of security and comfort for residents and deserve appreciation.
<br />
How much should you tip? It depends on your situation. This tipping calculator is based on how much you pay in rent or how much your building's maintenance fee is. This is a good starting point for deciding how much to tip. If you are able to afford a relatively high rent, you can afford to tip more generously.
<br />
This NYC tipping calculator is meant as a good starting point. You can obviously tip more or less than the suggested amount. Tipping more than the above calculator suggests can be a good idea.
<br />
If you only have a couple building workers you can just tip them the common <a href="https://www.brickunderground.com/blog/2013/11/brickundergrounds_2013_holiday_tipping_guide" target="_blank" class="link">recommended</a> amounts. But if you live in a larger building with many works this calculator becomes especially useful. 
<br />
You should tip more based on the services provided and the seniority of the staff.
<br />
A doorman who remembers your children's names, helps you with grocery bags, or provides a sense of safety might receive a more generous tip. Those who go above and beyond—helping during emergencies, providing local advice, or maintaining a consistently cheerful demeanor—are particularly valued.
<br />
Many building staff rely on holiday tips as a substantial part of their annual income, with some estimates suggesting that tips can represent a significant percentage of their total compensation. This economic reality makes the holiday tipping season not just a social custom, but an important economic exchange that recognizes the challenging and often under appreciated work of maintaining New York's complex residential infrastructure.
<br />
Do not use gift cards, baked goods, bibles, etc. as a replacement for cash. Your tips should be cash. Unless your building management informs you otherwise, you should put cash in individual envelopes and distribute them to your building staff (or leave them at the front desk so they can be distributed). Including a little card with some kind words is always a classy touch, but the cash is what matters most.`;

  const handleNonNegativeInput = (setter) => (e) => {
    if (e.target.value === "") {
      setter("");
      return;
    }
    const value = Math.max(0, Number(e.target.value)); // Prevent negative numbers
    setter(value);
  };

  const calculateTips = () => {
    const tipPool = feeAmount * (feeType === "Rent" ? 1 : 1.1);

    const totalWorkers = 
      Number(numDoormen) * 3 + 
      Number(numPorters) + 
      Number(numHandymen) * 2 + 
      Number(numSupers) * 4;

    if (totalWorkers === 0) return { doormen: 0, porters: 0, supers: 0, handymen: 0 };

    let baseTip = tipPool / totalWorkers;
    let doormanAmount = numDoormen > 0 ? baseTip * 3 : 0;
    let porterAmount = numPorters > 0 ? baseTip : 0;
    let handymenAmount = numHandymen > 0 ? baseTip * 2 : 0;

    return {
      doormen: doormanAmount,
      porters: porterAmount,
      handymen: handymenAmount,
      supers: baseTip * 4,
    };
  };

  const basePercent = () => {
    if (feeType === "Rent") {
      return 0.2;
    } else {
      return 1.0;
    }
  }

  const higherBasePercent = () =>{
    return basePercent() + 0.05;
  }

  const monthlyFeeName = () => {
    if (feeType === "Rent") {
      return "Rent"
    } else {
      return "Maintenance Fees"
    }
  }

  const getTipAmount = (base, percent) => {
    let amount = 12345;
    switch (base) {
    case SUPER:
       amount = tips.supers * percent;
      if (amount > 300) {
        return 300;
      }
      break;
    case PORTER:
      if (numPorters <= 0) {
        return 0;
      }
      amount = tips.porters * percent;
      if (amount > 100) {
        return 100;
      }
      break;
    case DOORMEN:
      if (numDoormen <= 0) {
        return 0;
      }
      amount = tips.doormen * percent;
      if (amount > 250) {
        return 250;
      }     
       break;
    case HANDYMEN:
      if (numHandymen <= 0) {
        return 0;
      }
      amount = tips.handymen * percent;
      if (amount > 200) {
        return 200;
      }
      break;
    default:
      break;
  }
  if (amount < 25) {
    return 25;
  }
  return amount;
  }

  const getTipNumber = (base, percent) => {
    return "$" + getTipAmount(base, percent).toFixed(2);
  }

  const getTotalTips = (percent) => {
    return getTipAmount(SUPER, percent) 
    + getTipAmount(DOORMEN, percent) * numDoormen 
    + getTipAmount(PORTER, percent) * numPorters 
    + getTipAmount(HANDYMEN, percent) * numHandymen;
  }

  const getSocialistTips = (percent) => {
    const tipPool = getTotalTips(percent);
    const numberOfEmployees = numDoormen + numSupers + numPorters + numHandymen;
    let eachTip = tipPool / numberOfEmployees;
    return "$" + eachTip.toFixed(2);
  }

  const tips = calculateTips();

  return (
    <div>
    <div className="p-6 max-w-xl mx-auto">
      <TitleText title={"NYC Holiday Tipping Calculator"} subtitle={"How much to tip your building workers."} />
      
      {/* Fee Type and Amount */}
      <div className="flex items-center gap-4">
        <div className="form-control w-1/2">
          <label className="label">
            <span className="label-text">Rent or Own?</span>
          </label>
          <select 
            className="select select-bordered" 
            value={feeType} 
            onChange={(e) => setFeeType(e.target.value)}
          >
            <option>Rent</option>
            <option>Own</option>
          </select>
        </div>
        <div className="form-control w-1/2">
          <label className="label">
            <span className="label-text">Your Monthly {monthlyFeeName()}</span>
          </label>
          <input 
            type="number" 
            className="input input-bordered" 
            value={feeAmount} 
            onChange={handleNonNegativeInput(setFeeAmount)} 
          />
        </div>
      </div>

      {/* Worker Inputs */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Number of Doormen/Concierge</span>
          </label>
          <input 
            type="number" 
            className="input input-bordered" 
            value={numDoormen} 
            onChange={handleNonNegativeInput(setNumDoormen)} 
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Number of Porters</span>
          </label>
          <input 
            type="number" 
            className="input input-bordered" 
            value={numPorters} 
            onChange={handleNonNegativeInput(setNumPorters)} 
          />
        </div>
      </div>

      <div className="form-control">
          <label className="label">
            <span className="label-text">Number of Handymen (not including Super)</span>
          </label>
          <input 
            type="number" 
            className="input input-bordered" 
            value={numHandymen} 
            onChange={handleNonNegativeInput(setNumHandymen)} 
          />
        </div>

      {/* Tip Breakdown */}
      <div className="mt-6">
        <h2 className="text-xl font-bold">Holiday Tip Breakdown 🎄</h2>
        <p>The Super/Resident Manager: {getTipNumber(SUPER, basePercent())} to {getTipNumber(SUPER, higherBasePercent())}</p>
        {numDoormen > 0 ? <p>Each Doormen/Concierge: {getTipNumber(DOORMEN, basePercent())} to {getTipNumber(DOORMEN, higherBasePercent())}</p> : <span/>}
        {numHandymen > 0 ? <p>Each Handymen: {getTipNumber(HANDYMEN, basePercent())} to {getTipNumber(HANDYMEN, higherBasePercent())}</p> : <span/>}
        {numPorters > 0 ? <p>Each Porters: {getTipNumber(PORTER, basePercent())} to {getTipNumber(PORTER, higherBasePercent())}</p> : <span/>}
        <div className="pt-2">
        <p>If you're a socialist you can give everyone the same amount: {getSocialistTips(basePercent())} to {getSocialistTips(higherBasePercent())}</p>
        <p><strong>Total Tip Amount: ${getTotalTips(basePercent()).toFixed(2)} to ${getTotalTips(higherBasePercent()).toFixed(2)}</strong></p>
        </div>
      </div>
      </div>
      <Description title={"NYC Holiday Tipping Guide"} mainText={mainText} />
      </div>
    );
}

export default NYCTipCalculator;
