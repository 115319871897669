import React from "react";

const DebouncedInput = ({
  textValue,
  setTextValue,
  handleKeyDown,
}) => {
  // Handle input change
  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  return (
    <div className="pb-2">
    <input
      className="input input-bordered input-primary w-full max-w-xs"
      type="text"
      value={textValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="Type Equation..."
    />
    </div>
  );
};

export default DebouncedInput;
