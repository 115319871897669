import React, { useState } from "react";
import { evaluate, format } from "mathjs";
import DebouncedInput from "../Helpers/DebouncedInput";
import TitleText from "../Helpers/TitleText";
import Description from '../Helpers/Description';

const ScientificCalculator = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [errorText, setErrorText] = useState("");

  const mainText = `This is a very powerful web calculator. To get the most power out of the calculator type directly into the input field. For example: 
<br />
<code>sqrt(3^2 + 7^2)</code>
<br />
<code>10 inch to cm</code>
<br />
<code>x = 15 / 4</code>
<br />
<code>a = 5.08 cm + 3 inch</code>
<br />
<code>sin(45 deg) ^ 3</code>
<br />
This calculator offers powerful mathematical capabilities for users across various domains. It can perform complex calculations including trigonometric functions, logarithmic and exponential computations, statistical analysis, and advanced algebraic operations. Users can input intricate mathematical expressions and receive precise, dynamic results instantly.
<br />
The calculator supports handling different number types like fractions, decimals, and complex numbers. It enables unit conversions, symbolic mathematics, and matrix calculations, making it versatile for professionals in fields such as engineering, finance, physics, and data science. Whether solving complex equations, performing scientific computations, or converting between measurement units, the calculator provides a robust, user-friendly mathematical tool.
<br />
Key features include real-time expression evaluation, error handling for invalid inputs, and the ability to work with variables and custom functions. `;

  // Handler for when buttons are clicked
  const handleButtonClick = (value) => {
    setErrorText("");
    if (value === "C") {
      setInput("");
      setOutput("0"); // Clear input
    } else if (value === "ans") {
      try {
        // Evaluate the expression using math.js and format the result
        const result = evaluate(input);
        setOutput(format(result, { precision: 14 }).toString());
      } catch (error) {
        setErrorText(error.toString());
      }
    } else if (value === "del") {
        setInput(input.slice(0, -1));
    } else {
      setInput(input + value);
    }
  };

  const returnButtonclick = (event) => {
    if (event.key === "Enter") {
      handleButtonClick("ans");
    }
  };

  return (
    <div>
    <div className="card w-96 bg-base-100 shadow-xl p-4">
        <TitleText title={"Scientific Calculator"}/>          
      <div className="text-right mb-2 p-2 bg-base-300 rounded text-2xl">
        {output || "0"}
      </div>
      <DebouncedInput
        textValue={input}
        setTextValue={setInput}
        handleKeyDown={returnButtonclick}
      />
      <div>{errorText}</div>
      <div className="grid grid-cols-4 gap-2">
        {/* Number Buttons */}
        {["7", "8", "9", "/"].map((btn) => (
          <button
            key={btn}
            className="btn btn-outline"
            onClick={() => handleButtonClick(btn)}
          >
            {btn}
          </button>
        ))}
        {["4", "5", "6", "*"].map((btn) => (
          <button
            key={btn}
            className="btn btn-outline"
            onClick={() => handleButtonClick(btn)}
          >
            {btn}
          </button>
        ))}
        {["1", "2", "3", "-"].map((btn) => (
          <button
            key={btn}
            className="btn btn-outline"
            onClick={() => handleButtonClick(btn)}
          >
            {btn}
          </button>
        ))}
        {["0", ".", "ans", "+"].map((btn) => (
          <button
            key={btn}
            className="btn btn-outline"
            onClick={() => handleButtonClick(btn)}
          >
            {btn}
          </button>
        ))}
        {/* Scientific Function Buttons */}
        {["sin", "cos", "tan", "log"].map((func) => (
          <button
            key={func}
            className="btn btn-primary"
            onClick={() => handleButtonClick(`${func}(`)}
          >
            {func}
          </button>
        ))}
        {["asin", "acos", "atan", "ln"].map((func) => (
          <button
            key={func}
            className="btn btn-primary"
            onClick={() => handleButtonClick(`${func}(`)}
          >
            {func}
          </button>
        ))}
        {/* Additional Scientific Buttons */}
        {["(", ")", "pi", "=="].map((btn) => (
          <button
            key={btn}
            className="btn btn-secondary"
            onClick={() => handleButtonClick(btn)}
          >
            {btn === "pi" ? "π" : btn}
          </button>
        ))}
        {["^", "sqrt", "e^x", "e"].map((btn) => (
          <button
            key={btn}
            className="btn btn-secondary"
            onClick={() =>
              handleButtonClick(
                btn === "sqrt" ? "sqrt(" : btn === "e^x" ? "e^" : btn
              )
            }
          >
            {btn === "sqrt" ? "√" : btn}
          </button>
        ))}
        {["%", "n!", "del", "C"].map((btn) => (
          <button
            key={btn}
            className="btn btn-secondary"
            onClick={() => handleButtonClick(btn === "n!" ? "!" : btn)}
          >
            {btn}
          </button>
        ))}
      </div>
    </div>
    <Description title={"Powerful Math Calculator"} mainText={mainText} />
    </div>
  );
};

export default ScientificCalculator;
