import React from "react";
import { Link } from "react-router-dom";

const LinkList = ({ links }) => {
  return (
    <div className="p-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {links.map((link, index) => (
        <Link
          to={link.url}
          key={index}
          className="rounded-lg border border-gray-200 p-4 hover:shadow-lg transition-shadow duration-200 bg-white"
        >
          <div className="text-blue-600 hover:text-blue-800 font-semibold text-lg">
            {link.title}
          </div>
          <p className="text-gray-500 mt-2">{link.description}</p>
        </Link>
      ))}
    </div>
  );
};

export default LinkList;
