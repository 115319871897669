import React from 'react';
import LinkList from '../Helpers/LinkList';

const HealthIndex = () => {
    const links = [
       // { title: 'Smoothie Calculator', url: '/health/smoothie/', description: 'Smoothie nutrition facts' },
        { title: 'TDEE Calcualtor', url: '/health/tdee', description: 'Different TDEE esitmates in one' },
        { title: 'Running Pace Calculator', url: '/health/runningpace', description: 'Estimate your running pace for different distances and races' },
        { title: 'Height Calculator', url: '/health/height', description: 'Convert different heights' },
      ];
    
      return (
          <div className="flex flex-col items-center p-4">
            <h2 className="text-2xl font-bold mb-4">Health Calculators</h2>
            <LinkList links={links} />
          </div>
      );
};

export default HealthIndex;