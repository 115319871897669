import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";

const CallCalculator = () => {
    // State to hold user inputs
    const [premium, setPremium] = useState("");
    const [strikePrice, setStrikePrice] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [daysToExpiration, setDaysToExpiration] = useState("");
    const [result, setResult] = useState("");

    const mainText = `Use this covered call calculator to determine how much annual interest you are actually earning by selling a covered call.
<br />
A covered call is an options trading strategy that allows investors to generate additional income from stocks they already own by selling call options on those shares. An investor who holds a specific stock writes a call option, giving another investor the right to purchase those shares at a predetermined price by a specific date. The option seller receives an upfront premium, which serves as immediate income.
<br />
The mechanism of income generation in covered calls differs significantly from traditional interest-bearing investments. While interest provides a predictable, periodic payment, covered call premiums are more variable and depend on factors like stock volatility and the chosen strike price. The strategy offers potential for higher returns compared to standard interest income, but it also introduces additional complexity and risk. The premium received provides a small buffer against potential stock price declines, functioning almost like an extra layer of protection for the underlying investment.
<br />
The premium from a covered call might be substantial, especially for stocks with higher volatility. For instance, an investor holding 100 shares of a stock trading at $50 might sell a call option with a $55 strike price and receive a $2 per share premium, totaling $200. If the stock price remains below $55, the investor keeps both the shares and the premium, creating an attractive additional income stream.
<br />
However, covered calls are not without limitations. The strategy can potentially restrict the upside potential of an investment, as the investor might be obligated to sell shares if the stock price rises above the strike price. This means that while generating income, an investor might miss out on significant stock appreciation. The approach works best for stocks that an investor is neutral or slightly optimistic about, and it requires a more active investment management style compared to passive interest-earning investments.`;

    // Effect to update daysToExpiration when expirationDate changes
    // useEffect(() => {
    //     if (expirationDate) {
    //         const today = new Date();
    //         const expiration = new Date(expirationDate);
    //         const timeDifference = expiration - today;
    //         const calculatedDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    //         setDaysToExpiration(calculatedDays > 0 ? calculatedDays : "");
    //     }
    // }, [expirationDate]);

    const updateExperationDate= (newDate) => {
        setExpirationDate(newDate)
        const today = new Date();
            const expiration = new Date(newDate);
            const timeDifference = expiration - today;
            const calculatedDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            setDaysToExpiration(calculatedDays > 0 ? calculatedDays : "");
    }

    // Effect to update expirationDate when daysToExpiration changes
    // useEffect(() => {
    //     if (daysToExpiration) {
    //         const today = new Date();
    //         const futureDate = new Date(today);
    //         futureDate.setDate(today.getDate() + parseInt(daysToExpiration, 10));
    //         setExpirationDate(futureDate.toISOString().split("T")[0]);
    //     }
    // }, [daysToExpiration]);

    const updateDaysToExpiration= (newDate) => {
        setDaysToExpiration(newDate)
            const today = new Date();
            const futureDate = new Date(today);
            futureDate.setDate(today.getDate() + parseInt(newDate, 10));
            setExpirationDate(futureDate.toISOString().split("T")[0]);
    }

    // Function to calculate the annualized return
    const calculateAnnualizedReturn = () => {
        const premiumValue = parseFloat(premium);
        const strikePriceValue = parseFloat(strikePrice);
        const daysToExpirationValue = parseInt(daysToExpiration);

        // Validate input values
        if (isNaN(premiumValue) || isNaN(strikePriceValue) || isNaN(daysToExpirationValue)) {
            setResult("Please enter valid inputs.");
            return;
        }

        if (premiumValue <= 0 || strikePriceValue <= 0 || daysToExpirationValue < 0) {
            setResult("Please enter positive values for all inputs  and ensure the expiration date is in the future.");
            return;
        }

        // Calculate the annualized return
        const annualizedReturn = ((premiumValue / strikePriceValue) * (365 / daysToExpirationValue) * 100).toFixed(2);
        setResult(`Annualized Return: ${annualizedReturn}%`);
    };

    return (
        <div>
        <div className="flex flex-col items-center p-6 bg-base-200 rounded-lg shadow-lg max-w-md mx-auto mt-10">
            <TitleText title={"Covered Call APY Calculator"} subtitle={"How much are sold calls earning?"} />
            
            {/* Premium Input */}
            <div className="form-control w-full max-w-xs mb-4">
                <label className="label">
                    <span className="label-text">Per Share Premium Received ($):</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    placeholder="e.g., 1.25"
                    value={premium}
                    onChange={(e) => setPremium(e.target.value)}
                />
            </div>

            {/* Strike Price Input */}
            <div className="form-control w-full max-w-xs mb-4">
                <label className="label">
                    <span className="label-text">Per Share Price ($):</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    placeholder="e.g., 250"
                    value={strikePrice}
                    onChange={(e) => setStrikePrice(e.target.value)}
                />
            </div>

            {/* Expiration Date Input */}
            <div className="form-control w-full max-w-xs mb-4">
                <label className="label">
                    <span className="label-text">Expiration Date:</span>
                </label>
                <input
                    type="date"
                    className="input input-bordered w-full"
                    value={expirationDate}
                    onChange={(e) => updateExperationDate(e.target.value)}
                />
            </div>

            {/* Days to Expiration Input */}
            <div className="form-control w-full max-w-xs mb-4">
                <label className="label">
                    <span className="label-text">Days to Expiration:</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    placeholder="e.g., 30"
                    value={daysToExpiration}
                    onChange={(e) => updateDaysToExpiration(e.target.value)}
                />
            </div>

            {/* Calculate Button */}
            <button className="btn btn-primary w-full" onClick={calculateAnnualizedReturn}>
                Calculate Annualized Return
            </button>

            {/* Result Display */}
            {result && (
                <div className="mt-6 text-lg font-semibold text-center text-primary">
                    {result}
                </div>
            )}
        </div>
        <Description title={"Covered Call Calculations"} mainText={mainText}/>
        </div>
    );
};

export default CallCalculator;
