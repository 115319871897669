import React, { useState } from "react";
// import "daisyui";
import TitleText from "../Helpers/TitleText";
import Description from '../Helpers/Description';

const HeightCalculator = () => {
  const [height, setHeight] = useState({
    feet: "",
    inches: "",
    centimeters: "",
    meters: "",
  });

  const mainText = "Easily transform between inches, centimeters, meters, and feet.";

  const updateHeight = (type, value) => {
    let feet = 0, inches = 0, centimeters = 0, meters = 0;

    if (value === "") {
        switch (type) {
            case "feet":
              feet = "";
              break;
            case "inches":
              inches = "";
              break;
            case "centimeters":
              centimeters = "";
              break;
            case "meters":
              meters = "";
              break;
            default:
              break;
          }
          setHeight({
            feet: feet,
            inches: inches,
            centimeters: centimeters,
            meters: meters,
          });

          return;
    }

    let floatValue = parseFloat(value) || 0;
    if (floatValue < 0) {
        floatValue = 0;
    }

    switch (type) {
      case "feet":
        feet = floatValue;
        inches = (feet * 12).toFixed(2);
        centimeters = (inches * 2.54).toFixed(2);
        meters = (centimeters / 100).toFixed(2);
        break;
      case "inches":
        inches = floatValue;
        feet = (inches / 12).toFixed(2);
        centimeters = (inches * 2.54).toFixed(2);
        meters = (centimeters / 100).toFixed(2);
        break;
      case "centimeters":
        centimeters = floatValue;
        inches = (centimeters / 2.54).toFixed(2);
        feet = (inches / 12).toFixed(2);
        meters = (centimeters / 100).toFixed(2);
        break;
      case "meters":
        meters = floatValue;
        centimeters = (meters * 100).toFixed(2);
        inches = (centimeters / 2.54).toFixed(2);
        feet = (inches / 12).toFixed(2);
        break;
      default:
        break;
    }

    setHeight({
      feet: feet,
      inches: inches,
      centimeters: centimeters,
      meters: meters,
    });
  };

  return (
    <div>
    <div className="p-5">
                  <TitleText title={"Height Calculator"} subtitle={"Convert feet, inches, cm, meters."} />
      <div className="grid grid-cols-1 gap-4 max-w-md">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Feet</span>
          </label>
          <input
            type="number"
            value={height.feet}
            onChange={(e) => updateHeight("feet", e.target.value)}
            placeholder="Enter feet"
            className="input input-bordered"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Inches</span>
          </label>
          <input
            type="number"
            value={height.inches}
            onChange={(e) => updateHeight("inches", e.target.value)}
            placeholder="Enter inches"
            className="input input-bordered"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Centimeters</span>
          </label>
          <input
            type="number"
            value={height.centimeters}
            onChange={(e) => updateHeight("centimeters", e.target.value)}
            placeholder="Enter centimeters"
            className="input input-bordered"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Meters</span>
          </label>
          <input
            type="number"
            value={height.meters}
            onChange={(e) => updateHeight("meters", e.target.value)}
            placeholder="Enter meters"
            className="input input-bordered"
          />
        </div>
      </div>
    </div>
    <Description title={"Height Conversions"} mainText={mainText} />
    </div>
  );
};

export default HeightCalculator;
