import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";

const PutCalculator = () => {
    // State to hold user inputs
    const [premium, setPremium] = useState("");
    const [strikePrice, setStrikePrice] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [daysToExpiration, setDaysToExpiration] = useState("");
    const [result, setResult] = useState("");
    const mainText = `Use this cash secured put calculator to see the equivalent interest you earn by selling a put option. This is helpful if you use Robinhood as your brokerage, as they do not allow you to earn interest on cash that is used for a sold put. So if the interest in this calculator is less than the interest Robinhood pays you to just hold cash, you are better off holding cash.
    <br />
    A cash secured put is an options trading strategy where an investor sells (writes) a put option while simultaneously setting aside enough cash to potentially buy the underlying stock if the option is exercised. When you sell a put option, you receive a premium upfront, which is your potential profit. The strategy works like this: you choose a stock you wouldn't mind owning at a lower price and sell a put option with a strike price at or below the current market price. If the stock price remains above the strike price by the option's expiration, the put option expires worthless, and you keep the entire premium as profit. However, if the stock price falls below the strike price, you are obligated to buy the shares at the strike price. The "cash secured" part means you have the cash reserved in your account to purchase the shares if assigned, which reduces the broker's risk and allows you to sell put options without additional margin requirements. This strategy can be an effective way to generate income through option premiums while potentially acquiring stocks at a discounted price.`;

    const updateExperationDate= (newDate) => {
        setExpirationDate(newDate)
        const today = new Date();
            const expiration = new Date(newDate);
            const timeDifference = expiration - today;
            const calculatedDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            setDaysToExpiration(calculatedDays > 0 ? calculatedDays : "");
    }

    const updateDaysToExpiration= (newDate) => {
        setDaysToExpiration(newDate)
            const today = new Date();
            const futureDate = new Date(today);
            futureDate.setDate(today.getDate() + parseInt(newDate, 10));
            setExpirationDate(futureDate.toISOString().split("T")[0]);
    }

    // Effect to update daysToExpiration when expirationDate changes
    // useEffect(() => {
    //     if (expirationDate) {
    //         const today = new Date();
    //         const expiration = new Date(expirationDate);
    //         const timeDifference = expiration - today;
    //         const calculatedDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    //         setDaysToExpiration(calculatedDays > 0 ? calculatedDays : "");
    //     }
    // }, [expirationDate]);

    // Effect to update expirationDate when daysToExpiration changes
    // useEffect(() => {
    //     if (daysToExpiration) {
    //         const today = new Date();
    //         const futureDate = new Date(today);
    //         futureDate.setDate(today.getDate() + parseInt(daysToExpiration, 10));
    //         setExpirationDate(futureDate.toISOString().split("T")[0]);
    //     }
    // }, [daysToExpiration]);

    // Function to calculate the annualized return
    const calculateAnnualizedReturn = () => {
        const premiumValue = parseFloat(premium);
        const strikePriceValue = parseFloat(strikePrice);
        const daysToExpirationValue = parseInt(daysToExpiration);

        // Validate input values
        if (isNaN(premiumValue) || isNaN(strikePriceValue) || isNaN(daysToExpirationValue)) {
            setResult("Please enter valid inputs.");
            return;
        }

        if (premiumValue <= 0 || strikePriceValue <= 0 || daysToExpirationValue < 0) {
            setResult("Please enter positive values for all inputs  and ensure the expiration date is in the future.");
            return;
        }

        // Calculate the annualized return
        const annualizedReturn = ((premiumValue / strikePriceValue) * (365 / daysToExpirationValue) * 100).toFixed(2);
        setResult(`Annualized Return: ${annualizedReturn}%`);
    };

    return (
        <div>
        <div className="flex flex-col items-center p-6 bg-base-200 rounded-lg shadow-lg max-w-md mx-auto mt-10">
            <TitleText title={"Cash-Secured Put APY Calculator"} subtitle={"Earnings for selling put options."} />

            {/* Premium Input */}
            <div className="form-control w-full max-w-xs mb-4">
                <label className="label">
                    <span className="label-text">Per Share Premium Received ($):</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    placeholder="e.g., 1.25"
                    value={premium}
                    onChange={(e) => setPremium(e.target.value)}
                />
            </div>

            {/* Strike Price Input */}
            <div className="form-control w-full max-w-xs mb-4">
                <label className="label">
                    <span className="label-text">Per Share Strike Price ($):</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    placeholder="e.g., 250"
                    value={strikePrice}
                    onChange={(e) => setStrikePrice(e.target.value)}
                />
            </div>

            {/* Expiration Date Input */}
            <div className="form-control w-full max-w-xs mb-4">
                <label className="label">
                    <span className="label-text">Expiration Date:</span>
                </label>
                <input
                    type="date"
                    className="input input-bordered w-full"
                    value={expirationDate}
                    onChange={(e) => updateExperationDate(e.target.value)}
                />
            </div>

            {/* Days to Expiration Input */}
            <div className="form-control w-full max-w-xs mb-4">
                <label className="label">
                    <span className="label-text">Days to Expiration:</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    placeholder="e.g., 30"
                    value={daysToExpiration}
                    onChange={(e) => updateDaysToExpiration(e.target.value)}
                />
            </div>

            {/* Calculate Button */}
            <button className="btn btn-primary w-full" onClick={calculateAnnualizedReturn}>
                Calculate Annualized Return
            </button>

            {/* Result Display */}
            {result && (
                <div className="mt-6 text-lg font-semibold text-center text-primary">
                    {result}
                </div>
            )}
        </div>
        <Description title={"Interest For Selling Puts"} mainText={mainText} />
        </div>
    );
};

export default PutCalculator;
