import React from "react";

const TitleText = ({ title, subtitle }) => {
  return (
    <div className="text-center">
            <h1 className="text-4xl font-bold text-primary">{title}</h1>
            <p className="text-xl text-gray-600 mt-2">{subtitle}</p>
        </div>
  );
};

export default TitleText;
