import React from 'react';
import LinkList from '../Helpers/LinkList';

const FinanceIndex = () => {
    const links = [
        { title: 'Cash Secured Put APY', url: '/finance/putapy/', description: 'Calculate annualized returns from selling a cash secured put' },
        { title: 'Covered Call APY', url: '/finance/callapy', description: 'Calculate annualized returns from selling a covered call' },
        { title: 'APY Interest Calculator', url: '/finance/apy', description: 'Calculate returns for interest rates' },
        { title: 'Price to Wage Calculator', url: '/finance/pricetowage', description: 'Calculate how long you have to work to buy something' },
        { title: 'Annual Income Calculator', url: '/finance/hourlywage', description: 'Annual salary to hourly wage, hourly to annual, plus tax estimate' },
        { title: 'NYC Holiday Tip Calculator', url: '/finance/nycholiday', description: 'Calculate how much to tip your doormen, etc.' },
        { title: 'NYC Rent Affordability Calculator', url: '/finance/nycrent', description: 'Calculate how much rent you can afford in NYC' },

      ];
      return (
          <div className="flex flex-col items-center p-4">
            <h2 className="text-2xl font-bold mb-4">Finance Calculators</h2>
            <LinkList links={links} />
          </div>
      );
};

export default FinanceIndex;